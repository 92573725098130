<template class="w-full">
  <div class="w-full">
    <Loader v-if="loading"
      class="w-full lg:w-main-container lg:h-088screen"
      :class="getHeightOfScreenWidth(1)"
    />
    <div v-else class="grid grid-cols-2 gap-4 w-full">
      <template v-if="postsData.length > 0">
        <div class="rounded" v-for="(post, index) in postsData" :key="post.id"
          :class="{
            'col-span-2' : index === 0 && pagination.currentPage===1,
            'row-span-1 lg:row-span-2': index === 1 && pagination.currentPage === 1,
            'col-span-2 lg:col-span-1': index > 0 || pagination.currentPage > 1,
          }"
        >
          <div class="lg:rounded lg:shadow-lg lg:border border-t border-b border-grayedHR">
            <template v-if="index === 0 && pagination.currentPage===1">
              <PostCard :post-data="post" />
            </template>
            <template v-else-if="index === 1 && pagination.currentPage===1 && !isMobile">
              <PostCard :narrow="true" :post-data="post" />
            </template>
            <template v-else>
              <PostCard :small="true" :post-data="post" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
	      <div class="rounded col-span-2">
	        <PostCard empty-post single-post :post-data="{
							image: emptyPost.image,
							title: emptyPost.title,
						}"
	        />
	      </div>
      </template>
    </div>
    <div class="mt-3" :class="{'hidden': loading}">
      <div class="flex-1 flex items-center justify-end">
        <div v-if="postsData.length > 0 && pagination.total > 6">
          <t-pagination
              variant="posts"
              :total-items="pagination.total"
              :per-page="pagination.perPage"
              :limit="6"
              v-model="currentPage"
              @change="next"
              :disabled="loadingPosts"
          ></t-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from "@/views/content/posts/components/PostCard";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ref} from "@vue/composition-api";
import Pagination from "@/views/content/components/Pagination";
import Loader from "@/views/Loader";
import {capitalize} from 'lodash';
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";


export default {
  name: "Posts",
  components: {Loader, Pagination, PostCard},
  data() {
    return {
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      isMobile: checkIsMobileDevice(),
      category: null,
      capitalize: capitalize,
    }
  },
  computed: {
    ...mapGetters('posts', ['postsData', 'pagination', 'loadingPosts', 'postsLoaded', 'searchQuery', 'emptyPost', 'lastPage']),
    ...mapGetters('loader', ['loading']),
	  ...mapGetters('lang', ['configData']),

	  getEmptyArticle() {
			return this.postsData.find(el => el.categorySlug === 'empty');
	  }
  },
  watch: {
    '$route.params.category': {
      handler: function(val, oldVal) {
				if(val && val !== oldVal) {
					this.setSearchQuery(null);
					this.category = val === undefined ? null : val;
					this.currentPage = 1;
					this.fetchPostsRequest({category: this.category, page: this.lastPage});
				}
      },
      deep: true,
      immediate: true,
    },
	  '$route.query.page': {
      handler: function(val) {
				if(val) {
					this.setLastPage(val);
					this.currentPage = val;
					this.fetchPostsRequest({
						page: val,
						category: this.searchQuery !== null ? null : this.$route.params.category,
						search: this.searchQuery
					});
				}
      },
      deep: true,
      immediate: true,
    },
	  '$route.query.search': {
      handler(val) {
				if(val) {
					this.currentPage = this.lastPage ? this.lastPage : 1;
					this.fetchPostsRequest({page: this.currentPage, search: val});
				}
      }
    },
    langLocale() {
      this.fetchPostsRequest({category: this.$route.params.category});
    },
  },
  methods: {
    ...mapActions('posts', ['fetchPostsRequest', 'fetchEmptyPostRequest']),
    ...mapMutations('posts', ['setSearchQuery', 'setLastPage']),
    next(val) {
      this.currentPage = val;
			this.$router.push({name: 'posts', params: {category: this.$route.params.category}, query: {page: this.currentPage, search: this.$route.params.search}})
    }
  },
  mounted() {
		this.fetchEmptyPostRequest();
	  if(!this.$route.params.category) {
		  this.$router.push({name: 'posts', params: {category: 'news'}})
	  }
  },
  setup() {
    const currentPage = ref(1)

    return { currentPage }
  },
}
</script>
