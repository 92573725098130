var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[(_vm.loading)?_c('Loader',{staticClass:"w-full lg:w-main-container lg:h-088screen",class:_vm.getHeightOfScreenWidth(1)}):_c('div',{staticClass:"grid grid-cols-2 gap-4 w-full"},[(_vm.postsData.length > 0)?_vm._l((_vm.postsData),function(post,index){return _c('div',{key:post.id,staticClass:"rounded",class:{
            'col-span-2' : index === 0 && _vm.pagination.currentPage===1,
            'row-span-1 lg:row-span-2': index === 1 && _vm.pagination.currentPage === 1,
            'col-span-2 lg:col-span-1': index > 0 || _vm.pagination.currentPage > 1,
          }},[_c('div',{staticClass:"lg:rounded lg:shadow-lg lg:border border-t border-b border-grayedHR"},[(index === 0 && _vm.pagination.currentPage===1)?[_c('PostCard',{attrs:{"post-data":post}})]:(index === 1 && _vm.pagination.currentPage===1 && !_vm.isMobile)?[_c('PostCard',{attrs:{"narrow":true,"post-data":post}})]:[_c('PostCard',{attrs:{"small":true,"post-data":post}})]],2)])}):[_c('div',{staticClass:"rounded col-span-2"},[_c('PostCard',{attrs:{"empty-post":"","single-post":"","post-data":{
							image: _vm.emptyPost.image,
							title: _vm.emptyPost.title,
						}}})],1)]],2),_c('div',{staticClass:"mt-3",class:{'hidden': _vm.loading}},[_c('div',{staticClass:"flex-1 flex items-center justify-end"},[(_vm.postsData.length > 0 && _vm.pagination.total > 6)?_c('div',[_c('t-pagination',{attrs:{"variant":"posts","total-items":_vm.pagination.total,"per-page":_vm.pagination.perPage,"limit":6,"disabled":_vm.loadingPosts},on:{"change":_vm.next},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }